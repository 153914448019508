@import './global-variables.scss';

.gulu-logo-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 5px;
  justify-items: center;
  .image-wrapper{
    display: flex;
    grid-column-start: 2;
    margin: 15px 0px;
    .gulu-logo{
      .image{
        width: 43px;
        height: 43px;
        border-radius: 5px;
      }
      .image-bottom{
        margin-top: -43px;
        border-radius: 5px;
      }
    }
  }
  .lang-wrapper {
    @include display-flex(flex-end, center);
    align-self: start;
    grid-column-start: 3;
    margin: 15px 0px;
    cursor: pointer;
    .lang-button {
      padding: 5px 20px;
    }
  }
}