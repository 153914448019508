@import './global-variables.scss';

.queue-title{
  @include display-flex(normal, normal);
  width: 95%;
  margin: 5% 0 2% 0;
  padding-left: 18px;
  font-weight: bold;
  font-size: 16px;
  color: #EEECF1;
}

.queue-title-underline{
  background: #222222;
  border-radius: 1px;
  width: 90%;
  height: 1px;
  margin: 0 auto;
}
