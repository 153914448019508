@import './global-variables.scss';

.queue-preview-wrapper {
  @include display-flex(normal, normal, column);
    padding-bottom: $queueContentBottomPadding;
  .queue-preview-content{
    width: 90%;
    margin: auto;

    input[type="checkbox"] {
      width: 6px;
      height: 15px;
    }

    input[type="checkbox"]:checked {
      margin: 0 5px;
    }

    .checkbox-wrapper{
      margin: 16px 0;
      @include display-flex(normal, center);
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      .checkbox{
        width: 16px;
        height: 16px;
        margin-right: 16px;
        background: #333333;
        background-clip: content-box;
        border-radius: 20%;
        cursor: pointer;
      }
      &.personal-data {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .preview-title{
      font-size: 14px;
      margin: 16px 0 5px 0;
    }

    .personal-data-wrapper{
      @include display-flex(space-between, center);
      .personal-data-contant{
        &>.preview-text {
          margin-bottom: 5px;
        }
        .personal-data-phone{
          @include display-flex(normal, normal);
          .phone{
            margin-left: 5px;
          }
        }
      }
      .image{
        width: 24px;
        height: 24px;
        margin: 0 0 0 auto;
      }
    }

    .preview-text{
      color: #05FF00;
      font-weight: bold;
      font-size: 14px;
      @include display-flex(space-between, center);
      text-align: center;
      line-height: 16px;

      .image{
        width: 24px;
        height: 24px;
        right: 20px;
        margin: 0 0 0 auto;
      }

      .table-name{
        @include display-flex(normal, normal);
        .text{
          font-size: 12px;
          font-weight: normal; 
        }
  
        .background {
          width: 16px;
          height: 16px;
          @include display-flex(center, center);
          text-align: center;
          font-size: 16px;
        }
      }
    }
    .edit-button {
      cursor: pointer;
    }
  }
}