@import './global-variables.scss';

.queue-time-section-wrapper {
  @include display-flex(normal, normal, column);
    padding-bottom: $queueContentBottomPadding;
  .time-section-radio-wrapper{
    padding: 19px;
    @include display-flex(normal, center);
    line-height: 18px;
    cursor: pointer;
    input[type="radio"] {
      flex-shrink: 0;
    }
    &.disable {
      color: #7B7B7B;
      cursor: initial;
      input[type="radio"] {
        border: 2px solid #7B7B7B;
      }
      .disabled-label {
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  }
}