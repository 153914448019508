@import './global-variables.scss';

.site-wrapper {
  @include display-flex(flex-start, center);
  padding: 8px 16px 18px 16px;
  .site-image{
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 50%;
  }
  .info{
    margin: 0 8px;
    width: 100%;
    .title{
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
    }
    .address{
      @include display-flex(normal, center);
      margin: 3px 0;
      .image{
        width: 16px;
        height: 18.5px;
        margin: 0 5px 0 0;
      }
      .text{
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
}