@import "./global-variables.scss";

.calendar {
  width: 100%;
  font-size: 12px;
  .header {
    @include display-flex(normal, center);
    width: 95%;
    height: 40px;
    padding: 0px 10px;
    background: #303030;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    .button-wrapper {
      font-size: 16px;
      font-weight: normal;
      width: 40px;
      height: 100%;
      .prev-month,
      .next-month {
        @include display-flex(center, center);
        width: 40px;
        height: 100%;
        cursor: pointer;
      }
    }
    .title {
      flex: 1;
      text-align: center;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  .weekdays-background{
    background: #303030;
    height: 28px;
    width: 100%;
    position: absolute;
    z-index: -1;
    max-width: 375px;
  }
  .calendar-content {
    width: 80%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;
    .weekdays {
      td {
        padding: 5px 0px;
      }
    }
    tbody {
      font-size: 14px;
      font-family: poppins;
      line-height: 14px;
      td {
        .date-wrapper {
          @include display-flex(center, center);
          min-height: 30px;
          margin: 5px 0px 5px 0px;
          border-radius: 5px;
          color: #7A7585;
          &:not(.disable) {
            cursor: pointer;
          }
          &.selected {
            background-color: #FFFFFF;
            color: #000000;
            width: 28px;
            height: 28px;
            margin: auto;
            border-radius: 50%;
          }
          &.disable {
            color: #3C3844;
          }
        }
      }
    }
  }
}