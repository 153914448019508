@import './global-variables.scss';

.site-detail-wrapper {
  .banner-list {
    @include display-flex(flex-start, center);
    overflow-x: auto;
    margin-bottom: 30px;
    .detail-image{
      width: 90%;
      margin: 0 auto;
    }
  }
  .detail-wrapper{
    .ticket-title{
      @include display-flex(flex-start, flex-start);
      color: #FFFFFF;
      font-weight: bold;
      font-size: 14px;
      margin: 9px auto 2px auto;
      width: 90%;
    }
  }

  .queue-button {
    @extend %button;
    border-radius: 5px;
    margin: 32px auto;
    background-color: #ffffff;
    color: #000000;
    padding: 6px 44px;
    &.disable {
      background: #7B7B7B;
      cursor: initial;
    }
  }
}