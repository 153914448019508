@import './global-variables.scss';

.drop-down-wrapper{
  @include display-flex(flex-start, center);
  .drop-down-title-wrapper {
    @include display-flex(flex-start, center);
    padding: 5px 0px;
    flex: 1;
    cursor: pointer;
  }
  .dropdown-arrow{
    @include display-flex(space-between, center);
    text-align: center;
    margin-left: auto;
    .image{
      width: 10px;
      height: 5px;
    }
    .change{
      -ms-transform: rotate(180deg); /* IE 9 */
      transform: rotate(180deg);
    }
  }
}

.tnc{
  color: #FFFFFF;
  white-space: pre-line;
  font-size: 14px;
  width: 90%;
  margin: 0 auto 12px auto ;
}

.display-none{
  display: none;
}