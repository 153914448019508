$pickupColor: #87FFE9;
$disableColor: #4a4a4a;
$desktopMinWidth: 720px;
$selectedColor: #1AB900;

$tableTypeAColor: #f85999;
$tableTypeBColor: #6cbb20;
$tableTypeCColor: #07a5f7;
$tableTypeDColor: #e69201;
$tableTypeEColor: #ca61fb;
$tableTypeFColor: #d7d221;

$tableTypeADarkColor: #32121F;
$tableTypeBDarkColor: #162506;
$tableTypeCDarkColor: #012131;
$tableTypeDDarkColor: #2E1D00;
$tableTypeEDarkColor: #281332;
$tableTypeFDarkColor: #2B2A07;

$queueContentBottomPadding: 75px;

%button {
  width: fit-content;
  padding: 10px;
  cursor: pointer;
}

.text-A {
  color: $tableTypeAColor;
}
.text-B {
  color: $tableTypeBColor;
}
.text-C {
  color: $tableTypeCColor;
}
.text-D {
  color: $tableTypeDColor;
}
.text-E {
  color: $tableTypeEColor;
}
.text-F {
  color: $tableTypeFColor;
}

.background-A {
  background-color: $tableTypeAColor;
}
.background-B {
  background-color: $tableTypeBColor !important;
}
.background-C {
  background-color: $tableTypeCColor;
}
.background-D {
  background-color: $tableTypeDColor;
}
.background-E {
  background-color: $tableTypeEColor;
}
.background-F {
  background-color: $tableTypeFColor;
}

.background-dark-A {
  background-color: $tableTypeADarkColor;
}
.background-dark-B {
  background-color: $tableTypeBDarkColor !important;
}
.background-dark-C {
  background-color: $tableTypeCDarkColor;
}
.background-dark-D {
  background-color: $tableTypeDDarkColor;
}
.background-dark-E {
  background-color: $tableTypeEDarkColor;
}
.background-dark-F {
  background-color: $tableTypeFDarkColor;
}
.background-site-info{
  background-color: #141414;
}

.logo-A path{
  fill: $tableTypeAColor !important;
}
.logo-B path{
  fill: $tableTypeBColor !important;
}
.logo-C path{
  fill: $tableTypeCColor !important;
}
.logo-D path{
  fill: $tableTypeDColor !important;
}
.logo-E path{
  fill: $tableTypeEColor !important;
}
.logo-F path{
  fill: $tableTypeFColor !important;
}

@mixin display-flex($justify, $align, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin calendar-hover {
  &:hover {
    &:not(.disable) {
      background: none;
      background-color: $hoverColor;
      color: #ffffff;
      .preorder {
        .dot {
          background-color: #ffffff;
          &.empty {
            background-color: transparent;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
}

%zig-zag-base {
  background-position: 10px 0px;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  content: " ";
  display: block;
  width: 100%;
  height: 20px;
}

@mixin zig-zag($color) {
  background-image: linear-gradient(-45deg, $color 14px, transparent 0), linear-gradient(45deg, $color 14px, transparent 0);
  @extend %zig-zag-base;
}

@mixin zig-zag-bottom($color) {
    background-image: linear-gradient(-135deg, $color 14px, transparent 0), linear-gradient(135deg, $color 14px, transparent 0);
    @extend %zig-zag-base;
}

@mixin zig-zag-line($color, $bottomColor) {
  background-image: linear-gradient(-45deg, $color 14px, transparent 0), linear-gradient(45deg, $color 14px, transparent 0);
  @extend %zig-zag-base;
  background-position: 10px 4.5px;
  &:after{
    background-image: linear-gradient(-45deg, $bottomColor 14px, transparent 0), linear-gradient(45deg, $bottomColor 14px, transparent 0);
    @extend %zig-zag-base;
    background-position: 10px 4.5px;
    margin-top: 1.5px;
  }
}