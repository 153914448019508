@import './global-variables.scss';

.queue-service-type-wrapper {
  @include display-flex(normal, normal, column);
    padding-bottom: $queueContentBottomPadding;
  .service-type-radio-wrapper{
    padding: 19px;
    @include display-flex(normal, center);
    line-height: 18px;
    .image{
      width : 18px;
      margin: 0 9px;
    }
  }
}