@import './global-variables.scss';

.queue-ticket-type-wrapper {
  @include display-flex(normal, normal, column);
    padding-bottom: $queueContentBottomPadding;
  .ticket-type-radio-wrapper{
    padding: 19px;
    @include display-flex(normal, center);
    line-height: 18px;
  }
}