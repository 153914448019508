@import './global-variables.scss';

.queue-table-size-wrapper {
  @include display-flex(center, center, column);
    padding-bottom: $queueContentBottomPadding;
  .number-btn-wrapper{
    @include display-flex(normal, normal);
    flex-wrap: wrap;
    width: 82%;
    .table-size-btn{
      @include display-flex(center, center);
      text-align: center;
      width: 84px;
      height: 84px;
      background: #171717;
      border-radius: 50%;
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      color: #FFFFFF;
      margin: 9px;
      &.selected {
        color: $selectedColor;
      }
    }
  }
}