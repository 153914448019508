@import './global-variables.scss';

.success-email-sent-wrapper {
  @include display-flex(center, center, column);
  margin-top: 30px;
  .success{
    font-size: 24px;
  }
  .sent{
    text-align: center;
    font-size: 16px;
    color: #808080;
    margin-top: 9px;
    .email{
      color: #FFFFFF;
      font-weight: bold;
    }
  }
}