@import './global-variables.scss';

.queue-personal-info-wrapper {
  @include display-flex(normal, normal, column);
  padding-bottom: $queueContentBottomPadding;
  .personal-info-wrapper{
    @include display-flex(normal, center, column);
    line-height: 18px;
    margin-bottom: 20px;
    .email-wrapper{
      @include display-flex(normal, normal, column);
      width: 100%;
      .email{
        margin: 17px auto 0 auto;
        width: 327px;
        height: 48px;
      }
      .email-error-message{
        width: 90%;
        margin: 0 0 17px 24px;
      }
    }
    .error{
      border: 2px solid #FF0000 !important;
    }
    .phone-content{
      @include display-flex(normal, normal, column);
      width: 100%;
      .phone-wrapper{
        @include display-flex(normal, normal);
        width: 327px;
        height: 48px;
        margin: auto;
        background: rgba(255, 255, 255, 0.2);
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 16px;
        .country-code-select{
          width: 25%;
          background: rgba(255, 255, 255, 0);
          border: 2px solid rgba(255, 255, 255, 0);
          border-radius: 12px;
          color: #ffffff;
          text-align: center;
        }
        .country-code-select option{
          color: #000000;
          background-color: #ffffff;
        }
        .country-code-select:focus option:checked {
          background: #E6E6E6;
        }
        .country-code-select:focus option:hover {
          background: #E6E6E6;
        }
        .image {
          width: 10px;
          height: 5px;
          display: inline; 
          margin-left: -30px;
          align-self: center;
        }
        .phone-input{
          width: 75%;
          background: rgba(255, 255, 255, 0);
          border: 2px solid rgba(255, 255, 255, 0);
          border-radius: 12px;
          margin: 0px;
        }
      }
      .phone-error-message{
        margin: 0 0 0 24px;
      }
    }
  }
  // input[type="checkbox"] {
  //   width: 16px;
  //   height: 16px;
  // }
  // input[type="checkbox"]:checked {
  //   margin: 0 5px;
  // }
  // .checkbox-wrapper{
  //   margin-left: 28px;
  //   @include display-flex(normal, center);
  //   font-size: 12px;
  //   line-height: 16px;
  //   color: #808080;
  //   width: 85%;
  //   margin: 0 auto 0 auto;
  //   cursor: pointer;
  //   .checkbox{
  //     width: 16px;
  //     height: 16px;
  //     margin-right: 16px;
  //     background: #333333;
  //     background-clip: content-box;
  //     border-radius: 20%;
  //   }
  // }
}