@import './global-variables.scss';

.queue-bottom-btn-wrapper {
  @include display-flex(space-evenly, center);
  position: fixed;
  bottom: 0;
  max-width: calc(375px - 52px);
  width: 100%;
  padding: 20px 26px;
  color: black;
  background-color: black;
  font-weight: 600;
  font-size: 25px;
  .queue-button {
    @include display-flex(center, center);
    width: 156px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    &.disable {
      background: #7B7B7B;
      cursor: initial;
    }
    &.previous {
      &:only-child {
        margin-right: auto;
      }
    }
    &.next {
      &:only-child {
        margin-left: auto;
      }
    }
  }
}