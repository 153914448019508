@import './global-variables.scss';

$height: 12px;
$halfheight: 6px;
$thickness: 120%; // increase to make the line thicker
$offset: 4px;

.ticket-wrapper {
  @include display-flex(normal, normal, column);
    padding-bottom: $queueContentBottomPadding;
  .tv-box{
    border-radius: 5px;
    width: calc(90% - 10px);
    padding: 5px;
    margin: auto;
    @include display-flex(center, center, column);
    .ticket-number{
      @include display-flex(center, center);
      font-weight: bold;
      font-size: 22px;
      line-height: 22px;
      width: 100%;
      position: relative;
      .image path {
        background-color: #000;
      }
      .reload-icon{
        right: 5%;
        position: absolute;
      }
      .text{
        margin-right: 30px;
        .sound-icon{
          margin-right: 30px;
        }
      }
    }
    .zig-zag-A{
      @include zig-zag-line($tableTypeAColor, $tableTypeADarkColor);
    }
    .zig-zag-B{
      @include zig-zag-line($tableTypeBColor, $tableTypeBDarkColor);
    }
    .zig-zag-C{
      @include zig-zag-line($tableTypeCColor, $tableTypeCDarkColor);
    }
    .zig-zag-D{
      @include zig-zag-line($tableTypeDColor, $tableTypeDDarkColor);
    }
    .zig-zag-E{
      @include zig-zag-line($tableTypeEColor, $tableTypeEDarkColor);
    }
    .zig-zag-F{
      @include zig-zag-line($tableTypeFColor, $tableTypeFDarkColor);
    }
    .time{
      font-family: Wallpoet;
      font-weight: normal;
      font-size: 20px;
    }
  }
  .ticket-box {
    width: 90%;
    margin: 0px auto;
    .zig-zag-A{
      @include zig-zag($tableTypeADarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-A{
        @include zig-zag-bottom($tableTypeADarkColor);
      }
    }
    .zig-zag-B{
      @include zig-zag($tableTypeBDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-B{
        @include zig-zag-bottom($tableTypeBDarkColor);
      }
    }
    .zig-zag-C{
      @include zig-zag($tableTypeCDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-C{
        @include zig-zag-bottom($tableTypeCDarkColor);
      }
    }
    .zig-zag-D{
      @include zig-zag($tableTypeDDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-D{
        @include zig-zag-bottom($tableTypeDDarkColor);
      }
    }
    .zig-zag-E{
      @include zig-zag($tableTypeEDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-E{
        @include zig-zag-bottom($tableTypeEDarkColor);
      }
    }
    .zig-zag-F{
      @include zig-zag($tableTypeFDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-F{
        @include zig-zag-bottom($tableTypeFDarkColor);
      }
    }
  }
  .ticket-box-content{
    border-radius: 5px;
    @include display-flex(center, center, column);
    .ticket-content{
      width: 100%;
      .ticket-number{
        @include display-flex(center, center);
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        font-size: 30px;
        padding: 3px;
        position: relative;
        .table-size{
          @include display-flex(center, center);
          position: absolute;
          right: 5%;
          font-size: 25px;
          padding-top: 3px;
          line-height: 25px;
          .image{
            width: 22px;
            margin-right: 3px;
          }
        }
      }
      .qrcode-wrapper{
        @include display-flex(center, center, column);
        width: 140px;
        height: 140px;
        padding: 10px;
        margin: 10px auto;
        border-radius: 10px;
        background-color: #ffffff;
        .redeemed{
          @include display-flex(center, center);
          background-color: rgba(0, 0, 0, 0.9);
          width: 160px;
          height: 160px;
          position: absolute;
          color: #ffffff;
          border-radius: 9px;
        }
      }
      .ticket-detail-wrapper{
        @include display-flex(normal, normal, column);
        flex-direction: column;

        .ticket-detail{
          @include display-flex(normal, normal, column);
          width: 100%;
        }
      }

      .ticket-title{
        @include display-flex(normal, normal, column);
        color: #FFFFFF;
        font-weight: normal;
        font-size: 14px;
        margin: 9px auto 2px auto;
        width: 90%;
      }

      .ticket-text{
        @include display-flex(normal, flex-start, column);
        color: #FFFFFF;
        font-weight: 700;
        font-size: 14px;
        margin: 2px auto 9px auto;
        width: 90%;
      }
    }
  }
}

.middle-line{
  width: 90%;
  margin: 0 auto;
  background: #B0AFB5;
  backdrop-filter: blur(4px);
  border-radius: 20px;
  height: 1px;
}